<template>
  <main class="empty-container">
    <div class="error-main-container">
      <img v-if="isImage" src="@/assets/media/career/003-ufo.svg" />
      <h1>{{ title }}</h1>
      <p
        class="error-description"
      >{{ description }}</p>
      <a href="#" @click="handleClick">{{ btnText }}</a>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    title: {
      default: "Not Available",
      type: String,
    },
    description: {
      default: "We're sorry, the page you requested could not be found. Please redirect back to home",
      type: String,
    },
    isImage: {
      default: true,
      type: Boolean,
    },
    clickProps: {
      default: () => {
        return {};
      },
      type: Function,
    },
    btnText: {
      default: "Go Back to Home",
      type: String,
    }
  },
  methods: {
    returnToHome() {
      window.location = "/";
    },

    handleClick() {
      if (this.clickProps) {
        this.clickProps();
      } else {
        this.returnToHome()
      }
    }
  }
};
</script>

<style>
</style>