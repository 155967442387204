<template>
  <!-- Show loading spinner if content is still loading -->
  <div v-if="isLoading" class="loading-section">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div v-else>
    <main class="portfolio blog-main" v-if="getBlogList.length !== 0">
      <section class="hero">
        <div class="hero-border-left"></div>

        <div class="hero-block fade-in-bck">
          <div class="hero-title fade-in-bck">
            Countable Blogs
            <span class="hero-title-bar fade-in-bck"></span>
          </div>

          <div class="hero-subtitle">
            Be updated to the latest news about stuff happening in Countable.
            <a
              data-mixpanel="portfolio_cta_get_free_consultation"
              href="mailto:info@countable.ca"
              class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
            >LET'S TALK</a>
          </div>
        </div>

        <div class="hero-white-box-bottom"></div>
      </section>

      <div v-if="getBlogList.length !== 0" class="blog-list-container">
        <div
          class="blog-item"
          v-for="(blog, index) in getBlogList"
          :key="blog.id"
          :class="`${index === 0 ? 'blog-feature' : ''}`"
          @click="redirectToBlog(blog.id)"
        >
          <div class="blog-img-thumbnail">
            <img :src="returnImage(blog.blog_picture)" />
            <span class="blog-tag">
              <span>{{ blog.blog_type }}</span>
              <span v-if="index === 0">Featured</span>
            </span>
          </div>
          <div class="blog-content-container">
            <span class="blog-title">{{ blog.title }}</span>
            <span class="blog-content">{{ blog.content.substring(0, 150) }}...</span>
            <span class="blog-date">
              <i class="fas fa-clock"></i>
              {{ getTimestamp(blog.created_at) }}
            </span>
          </div>
        </div>
      </div>
    </main>

    <empty-container v-else  title="No Blogs Available" :is-image="false" />

  </div>
</template>

<script>
import API from "@/api/utils";
import EmptyContainer from "@/components/EmptyContainer";

export default {
  data() {
    return {};
  },
  components: {
    EmptyContainer
  },
  created() {
    this.$store.dispatch("fetchBlogAPI");
  },
  computed: {
    getBlogList() {
      return this.$store.getters.getBlogList;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    returnImage(imgLoc) {
      const host = API.getBaseURL();
      const img = imgLoc ? imgLoc.image.url : "";
      return `${host}${img}`;
    },
    redirectToBlog(id) {
      this.$router.push({
        name: "BlogDetail",
        params: {
          id: id
        }
      });
    },
    getTimestamp(date) {
      const resultDate = Date.now() - Date.parse(date);
      const differenceInHours = Math.ceil(Math.abs(resultDate) / 36e5);
      const differenceInDays = Math.ceil(resultDate / (1000 * 60 * 60 * 24));
      const differenceInMins = Math.floor(resultDate / 1000 / 60);

      if (differenceInMins < 60) {
        return `${differenceInMins} minute${
          differenceInMins <= 1 ? "" : "s"
        } ago `;
      } else if (differenceInHours > 24) {
        return `${differenceInDays} day${
          differenceInDays === 1 ? "" : "s"
        } ago `;
      } else if (differenceInDays > 31) {
        return new Date(date).toISOString().split("T")[0];
      } else {
        return `${differenceInHours} hour${
          differenceInHours === 1 ? "" : "s"
        } ago`;
      }
    }
  }
};
</script>
